import '@/scss/atc.scss';

import { useFeatures } from 'reaxl-features';

export default function AtcTheme() {
    const {
        atc_button_redesign: [isAtcButtonRedesignEnabled],
    } = useFeatures([
        'atc_button_redesign',
    ]);

    if (isAtcButtonRedesignEnabled) {
        import('@/scss/atc-button-test.scss');
    }

    return null;
}
